<template>
  <div class="login">
    <TopLogo/>
    <div class="login-tab">
      <MusicType :width="952" :type="type" :tabsList="tabsList"
                 :dropdownList="dropdownList"></MusicType>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import TopLogo from "@/components/main/TopLogo";
import MusicType from "@/components/content/MusicType"

export default {
  props: {},
  data() {
    return {
      // tabs数据
      tabsList: [
        {
          name: this.$t('vCodeLoginText'),
          url: '/login/vcodelogin'
        },
        {
          name: this.$t('passwordLoginText'),
          url: '/login/passwordlogin'
        }
      ],
      // 全部下来菜单
      dropdownList: [],
      type: false
    }
  },
  components: {
    TopLogo,
    MusicType
  }
  ,
  computed: {}
  ,
  created() {
  }
  ,
  mounted() {
  }
  ,
  watch: {}
  ,
  methods: {}
}
</script>

<style scoped lang='less'>
.login {
  &-tab {
    margin-top: 23px;
  }
}
</style>
